@import "~@fontsource/montserrat/index.css";



/* PC and Desktop*/
/*
/////////////////////////////////
//////////// header
////////////////////////////////
*/

.alert-title-grammage {

    background-image: url('/src/Components/img/header.jpg');
    background-repeat: round;
    border-color: #009ee37d;
    background-size: cover;
}

div#header {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 24vh;
    right: 0px;
    overflow: hidden;
}

div#footer {
    position: absolute;
    bottom: 0px;
    height: 14vh;
    left: 0px;
    right: 0px;
    overflow: hidden;
    color: #f8f9fa;
    font-family: 'Monserrat';
    font-size: 0.7em;
    background-size: cover;
}

div#imgVisu {
    margin-top: -38%;
    /* scale: 0.6; */
}

img#fleche_header_bottom {
    margin-top: 0%;
}


/*
/////////////////////////////////
////////////
////////////////////////////////
*/

/*
/////////////////////////////////
//////////// body
////////////////////////////////
*/

custom-class {
    display: none;
}

div {
    font-family: "Montserrat";
    font-weight: bold;
}

div#body_div {
    height: 60vh;
    margin-top: 14%;
    overflow-y: scroll;
    overflow-x: hidden;
}

label.form-label {
    font-size: 2em;
}

/*
/////////////////////////////////
////////////
////////////////////////////////
*/

/*
/////////////////////////////////
//////////// footer
////////////////////////////////
*/


/*
/////////////////////////////////
//////////// Preview
////////////////////////////////
*/

img#preview {
    border: 0.1mm ridge #212529;
    background-color: red;
}
div#modalPreview {
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: scroll;
    border-radius: 4px;
    outline: none;
    height: 100vh;
    width: 100%;
    padding: 20px;
}

/*
/////////////////////////////////
////////////
////////////////////////////////
*/

.alert-footer-grammage {
    color: #f8f9fa;
    background-color: #bdd031;
    border-color: #bdd031;
    font-family: 'Monserrat';
    background-image: url('/src/Components/img/footer.jpg');
    font-size: 1em;
    height: 150px;
    background-size: cover;
}

/*
/////////////////////////////////
////////////
////////////////////////////////
*/

/*
/////////////////////////////////
//////////// Bouton
////////////////////////////////
*/

.btn-support, .btn-previsualize {
    color: #fff;
    background-color: #bdd031;
    border-color: #bdd031;
    font-family: "Montserrat";
    font-weight: bold;
}

.btn-print {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    font-family: "Montserrat";
    font-weight: bold;
}

.btn-annule {
    color: #fff;
    background-color: #e42024;
    border-color: #e42024;
    font-family: "Montserrat";
    font-weight: bold;
}

.btn-editor {
    color: #fff;
    background-color: #129649;
    border-color: #129649;
    font-family: "Montserrat";
    font-weight: bold;
}

.btn-download {
    color: #fff;
    background-color: #009ee3;
    border-color: #009ee3;
    font-family: "Montserrat";
    font-weight: bold;
}

.btn-selected {
    color: #fff;
    background-color: #bdd031;
    border-color: #bdd031;
    font-family: "Montserrat";
    font-weight: bold;
}

#btn-importation-image {
    color: #fff;
    background-color: #129649;
    border-color: #129649;
    font-family: "Montserrat";
    font-weight: bold;
}

#btn-download-visual {
    color: #fff;
    background-color: #bdd031;
    border-color: #bdd031;
    font-family: "Montserrat";
    font-weight: bold;
}

a#link-download {
    color: white;
}

#btn-close-modal {
    color: #fff;
    background-color: #e42024;
    border-color: #e42024;
    font-family: "Montserrat";
    font-weight: bold;
}

button.btn-defaultDimmensionStory.btn.btn-outline-info, button.btn-defaultDimmensionInstaCarre.btn.btn-outline-info, button.btn-defaultDimmensionInstaVertical.btn.btn-outline-info {
    width: -webkit-fill-available;
    display: block;
}

button#dropdown-basic-button {
    background-color: #32d2f2;
    border: #32d2f2;
}

/*Hidde bouton format smartphone*/
button#btn-annul-phone, button#btn-editor-phone, button#btn-prev-phone, button#btn-download-phone {
    display: none
}

/*
/////////////////////////////////
////////////
////////////////////////////////
*/

/*
/////////////////////////////////
//////////// Form
////////////////////////////////
*/

input#input-L, input#input-H,input#input-L-formatdefault,input#input-H-formatdefault {
    display: inline;
    width: 48%;
    margin-right: 2%;
    border-color: darkgrey;
    border-bottom-width: thick;
    border-radius: 17px;
}

input#input-grammage {
    width: 98%;
    margin-right: 2%;
    border-color: darkgrey;
    border-bottom-width: thick;
    border-radius: 17px;
}

/*
/////////////////////////////////
////////////
////////////////////////////////
*/

/*
/////////////////////////////////
//////////// Media
////////////////////////////////
*/

img#fleche_header_top {
    margin-top: 2%;
}

img#logo_bpm {
    width: 226px;
}

p#titletext {
    margin-left: 2%;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    color: white;
    font-size: 3em;
    position: relative;
    margin-bottom: -9%;
}

p#subtitletext {
    margin-left: 3%;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    color: white;
    font-size: 1.4em;
    margin-top: 1.5%;
    margin-bottom: -4%;
    position: relative;
}

/*
/////////////////////////////////
////////////
////////////////////////////////
*/


/*
/////////////////////////////////
////////////Editeur image
////////////////////////////////
*/

div#PageEditor {
    border-style: ridge;
    position: absolute;
}

/*
/////////////////////////////////
////////////
////////////////////////////////
*/

/*pc portable S*/
@media (min-width: 1020px) and (max-width: 1200px) {
    p#subtitletext {
        margin-left: 2%;
        font-family: "Montserrat", sans-serif;
        font-weight: 100;
        color: white;
        font-size: 3em;
        position: relative;
        margin-bottom: -12%;
    }
}

/*telephone*/
@media only screen and (max-width: 600px) {
    /*
/////////////////////////////////
//////////// header
////////////////////////////////
*/
    p#titletext {
        margin-left: 3%;
        font-family: "Montserrat";
        font-weight: 100;
        color: white;
        font-size: 0.9em;
        position: relative;
        margin-bottom: -9%;


    }

    p#subtitletext {
        margin-left: 1%;
        font-family: "Montserrat";
        font-weight: 100;
        color: white;
        font-size: 0.4em;
        margin-top: 5.5%;
        margin-bottom: -4%;
        position: relative;
    }

    div#header {
        height: 15vh;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
    }

    /*
/////////////////////////////////
////////////
////////////////////////////////

*/
    /*
/////////////////////////////////
//////////// body
////////////////////////////////

*/
    canvas {
        height: 10vh;
        margin-top: 40%;
        width: 19vw;
    }

    div#body_div {
        height: 60vh;
        margin-top: 33%;
        overflow: scroll;
        /*overflow-y: scroll;*/
        /*overflow-x: hidden;*/
        width: 100%

    }



    /*
/////////////////////////////////
////////////
////////////////////////////////

*/
    /*
/////////////////////////////////
//////////// Footer
////////////////////////////////

*/
    .alert-footer-grammage {
        color: #f8f9fa;
        background-color: #bdd031;
        border-color: #bdd031;
        font-family: 'Monserrat';
        font-size: 0.4em;
        margin-top: 8%;
    }

    div#footer {
        position: fixed;
        left: 0%;
        bottom: 0%;
        height: 10vh;
        width: 100%;
    }

    /*
/////////////////////////////////
////////////
////////////////////////////////

*/
    /*
/////////////////////////////////
//////////// Bouton
////////////////////////////////

*/
    button#btn-annul, button#btn-prev, button#btn-download, button#btn-editor {
        display: none;
    }

    button#btn-annul-phone, button#btn-editor-phone, button#btn-prev-phone, button#btn-download-phone {
        display: inline-block;
    }

    .btn-download {
        color: #fff;
        background-color: #009ee3;
        border-color: #009ee3;
        font-family: "Montserrat";
        font-weight: bold;
    }

    .btn-editor {
        color: #fff;
        background-color: #129649;
        border-color: #129649;
        font-family: "Montserrat";
        font-weight: bold;
    }


    .btn-group, .btn-group-vertical {
        position: relative;
        display: inline-flex;
        vertical-align: middle;
        display: inline;
    }

    /*
    /////////////////////////////////
    ////////////
    ////////////////////////////////

    */
    /*
/////////////////////////////////
//////////// Form
////////////////////////////////

*/
    /*
/////////////////////////////////
////////////
////////////////////////////////

*/
    /*
/////////////////////////////////
//////////// Media
////////////////////////////////

*/
    img#fleche_header_top {
        margin-bottom: 1%;
        width: 150px;
    }

    img#fleche_header_bottom {
        width: 100px;
        margin-top: -2.5%;
    }

    img#logo_bpm {
        width: 50px;
        margin-left: 68%;
        float: right;
        position: fixed;
        margin-top: -13%;
    }

    /*
/////////////////////////////////
////////////
////////////////////////////////

*/
    div#PageEditor {
        clear: both;
        border-style: ridge;
        /* position: absolute; */
        transform: scale(0.2);
        margin: -108%;
        position: fixed;
    }


    div#footer {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        overflow: hidden;
        color: #f8f9fa;
        /* background-color: #bdd031; */
        /* border-color: #bdd031; */
        font-family: 'Monserrat';
        font-size: 1em;
        height: 12vh;
        background-size: cover;
    }

}

@media (min-width: 801px) and (max-width: 900px) {
    div#footer {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        overflow: hidden;
        color: #f8f9fa;
        /* background-color: #bdd031; */
        /* border-color: #bdd031; */
        font-family: 'Monserrat';
        font-size: 1em;
        height: 17vh;
        background-size: cover;
    }

}

/*tablette*/
@media (min-width: 601px) and (max-width: 800px) {
    @media screen and (orientation: landscape) {
        /*
    /////////////////////////////////
    //////////// header
    ////////////////////////////////

    */
        p#titletext {
            margin-left: 3%;
            font-family: "Montserrat", sans-serif;
            font-weight: 100;
            color: white;
            font-size: 3.4em;
            position: relative;
            margin-bottom: -12%;
        }

        p#subtitletext {
            margin-left: 3%;
            font-family: "Montserrat", sans-serif;
            font-weight: 100;
            color: white;
            font-size: 0.8em;
            margin-top: 0.5%;
            margin-bottom: -4%;
            position: relative;
        }
    }

    /*
    /////////////////////////////////
    //////////// header
    ////////////////////////////////

    */
    p#titletext {
        margin-left: 3%;
        font-family: "Montserrat", sans-serif;
        font-weight: 100;
        color: white;
        font-size: 1.8em;
        position: relative;
        margin-bottom: -10%;
    }

    p#subtitletext {
        margin-left: 3%;
        font-family: "Montserrat", sans-serif;
        font-weight: 100;
        color: white;
        font-size: 0.8em;
        margin-top: 0.5%;
        margin-bottom: -4%;
        position: relative;
    }

    /*
    /////////////////////////////////
    ////////////
    ////////////////////////////////
    */
    /*
    /////////////////////////////////
    //////////// body
    ////////////////////////////////

    */
    canvas {
        margin-top: 1%;
        width: 150px;
        height: 150px;
    }

    .canvas-imgprint {
        display: none;
    }

    div#body_div {
        height: 70vh;
        margin-top: 21%;
        overflow: hidden;
    }


    /*
    /////////////////////////////////
    ////////////
    ////////////////////////////////
    */
    /*
    /////////////////////////////////
    //////////// footer
    ////////////////////////////////
    */
    .alert-footer-grammage {
        color: #f8f9fa;
        background-color: #bdd031;
        border-color: #bdd031;
        font-family: 'Monserrat';
        font-size: 0.8em;
        height: 150px;
    }

    /*
    /////////////////////////////////
    ////////////
    ////////////////////////////////
    */
    /*
    /////////////////////////////////
    //////////// form
    ////////////////////////////////

    */
    /*
    /////////////////////////////////
    ////////////
    ////////////////////////////////
    */
    /*
    /////////////////////////////////
    //////////// bouton
    ////////////////////////////////

    */
    button#btn-annul, button#btn-prev, button#btn-download, button#btn-editor {
        display: none;
    }

    button#btn-annul-phone, button#btn-prev-phone, button#btn-download-phone {
        display: inline-block;
    }


    /*
    /////////////////////////////////
    ////////////
    ////////////////////////////////
    */
    /*
    /////////////////////////////////
    //////////// media
    ////////////////////////////////

    */
    img#fleche_header_top {
        margin-bottom: 1%;
        width: 300px;
    }

    img#fleche_header_bottom {
        width: 200px;
        margin-top: -1%;
    }

    img#logo_bpm {
        width: 144px;
    }

    /*
    /////////////////////////////////
    ////////////
    ////////////////////////////////
    */
}